import React from 'react';
import Layout from '../components/layout/layout';

const NotFoundPage = () => {
    return (
        <Layout>
            <h1>404 Not Found</h1>
        </Layout>
    );
}
 
export default NotFoundPage;